<template>
    <div>
        <shop-config-display :category="category"></shop-config-display>
    </div>
</template>

<script>
import ShopConfigDisplay from '@/components/elements/shop-config/ConfigDisplay'
export default {
    name: 'ShopConfigOrders',
    components: { ShopConfigDisplay },
    data () {
        return {
            category: 'ORDERS',
        }
    },
}
</script>
\
